<template>

	<div class="about">

		<section class="header">
			<div class="container">
				<h1>About</h1>
			</div>
		</section>

		<section class="description">
			<div class="container">
				<h2>Designer! Developer; Creative&nbsp;Solutions.</h2>
				<p>After graduating from Sinclair Community College with a degree in Visual Communications, I worked in many positions including print design, marketing, web development, and freelance art with a focus on lettering. These experiences gave me the great advantage to see all kinds of creative processes from varying perspectives, allowing me to adopt my own unique and effective framework. My current capacity includes web development, web design, logos, branding, print design, illustration, and lettering. My focus is on your organization's communication goals first, that is, what do you need to say and who needs to hear it?</p>
				<p><a href="mailto:brianstephens3@gmail.com" class="blue-link">Let's Get Started</a></p>
			</div>
		</section>

		<section class="clients">
			<div class="container">
				<h2>Client's I've WOrked With</h2>
				<ul class="logos">
					<li><img :src="file('CL-afrl.png')"></li>
					<li><img :src="file('CL-hoopla.png')"></li>
					<li><img :src="file('CL-gator.png')"></li>
					<li><img :src="file('CL-stratacache.png')"></li>
					<li><img :src="file('CL-homefront.png')"></li>
					<li><img :src="file('CL-osp.png')"></li>
					<li><img :src="file('CL-bjp.png')"></li>
					<li><img :src="file('CL-prnhealth.png')"></li>
				</ul>
			</div>
		</section>

		<section class="skills">
			<div class="container">
				<h2>Skills & Experience</h2>
				<div class="text">
					<div class="col">
						<ul>
							<li>Languages</li>
							<li>HTML</li>
							<li>CSS/SCSS</li>
							<li>Javascript</li>
							<li>Some PHP</li>
						</ul>
						<ul>
							<li>Build</li>
							<li>Wordpress</li>
							<li>Squarespace</li>
							<li>Bootstrap</li>
							<li>Vue</li>
							<li>Laravel</li>
							<li>Angular</li>
						</ul>
						<ul>
							<li>Adobe</li>
							<li>Photoshop</li>
							<li>Illustrator</li>
							<li>InDesign</li>
							<li>XD</li>
						</ul>
					</div>
					<div class="col">
						<ul>
							<li>Branding</li>
							<li>Logo Design</li>
							<li>Brand Guide</li>
						</ul>
						<ul>
							<li>UI/UX</li>
							<li>Website Design</li>
							<li>Mobile Design</li>
							<li>Wireframing</li>
						</ul>
						<ul>
							<li>Print</li>
							<li>Business Card</li>
							<li>Apparel</li>
							<li>Direct Mail</li>
							<li>Food/Drink Menu</li>
							<li>Table Tents</li>
							<li>Tradeshow Banner</li>
							<li>Product Flyer</li>
							<li>Product Catalog</li>
						</ul>
					</div>
					<div class="col">
						<div class="job">
							<p class="dates">August 2017 - Present</p>
							<h3>Owner & Sole Proprietor</h3>
							<h4>Stephens Designs LLC</h4>
							<p>All company facilities including sales, marketing, finance, project management, graphic design, and web development.</p>
						</div>
						<div class="job">
							<p class="dates">March 2014 - August 2017</p>
							<h3>Front-End Web Developer</h3>
							<h4><a href="https://www.realart.com/" target="_blank">Real Art Design Group</a></h4>
							<p>Primary duties include front-end web development, utilizing my knowledge of HTML, CSS, and Javascript to build robust interactive experiences that are easy to use and understand. Secondarily, I’ve been involved with large scale projects that required my expertise in lettering, calligraphy, sign painting, graphic design, and fabrication.</p>
						</div>
						<div class="job">
							<p class="dates">June 2013 - March 2014</p>
							<h3>Product Manager, Marketing</h3>
							<h4><a href="https://www.parts-express.com/" target="_blank">Parts Express International</a></h4>
							<p>Manage all of the Home A/V related products by watching market trends, testing and buying new products, building relationships with vendors, using demographic specific advertising strategies, writing active product copy, and setting strategic pricing structures to stay competitive in the industry.</p>
						</div>
						<div class="job">
							<p class="dates">June 2010 - June 2013</p>
							<h3>Graphic Designer, Print</h3>
							<h4><a href="https://www.parts-express.com/" target="_blank">Parts Express International</a></h4>
							<p>Annual catalog production, 32-page sales flyer production (6 per year), magazine advertisements, tradeshow both designs, tradeshow banners, event signage, and direct mail marketing pieces.</p>
						</div>
						<p><a href="https://www.dropbox.com/s/fita6asjnb4nm37/brianstephens_resume.pdf?raw=1" target="_blank" class="blue-link arrow">View Resume</a></p>
					</div>
				</div>
			</div>
		</section>

	</div>

</template>

<script>

	export default {

		name: "about",

		// data() { return {} },
		mounted() {
		},
		// methods: {}

	};

</script>






