export default [
	{
		title: "AKA Media Inc",
		slug: "aka-media",
		type: "Case Study",
		list: {
			bg: "WK-aka-media_list-bg.jpg",
			logo: "WK-aka-media_logo-wh.png"
		},
		details: {
			goals: [
				"Logo",
				"Brand Guide",
				"Business Card Design",
				"Email Signature",
				"Website Design",
				"Website Development",
				"Social Media Graphics",
				"Slideshow Graphics"
			],
			summary: "AKA Media is a branded video agency from Chicago, Illinois. They sepecialize in producing and distributing video content for big brands. They approached me in 2017 to do an overhaul for thier brand. We started working on a new logo with mood borads and sketches, after a few rounds of digital concepts we landed at the final decision of the ‘camera frame’ concept. From there I supplied options for colors and fonts so we could put together the brand guide. Being a creative agency, we wanted the branding to be playful and professional. Rounding out the brand guide with textures, images, and icons, we had everything we needed to create the remaining assets.",
			images: {
				header: "WK-aka-media_header.jpg",
				one: "WK-aka-media_01.jpg",
				two: "WK-aka-media_02.jpg",
				three: "WK-aka-media_03.jpg",
				four: "WK-aka-media_04.jpg",
				five: "WK-aka-media_05.jpg",
				six: "WK-aka-media_06.jpg",
				fonts: "WK-aka-media_fonts.jpg"
			},
			colors: [
				"#4da1bf",
				"#242729",
				"#bcab75",
				"#f3f4f4"
			]
		}
	},
	{
		title: "Dream Village",
		slug: "dream-village",
		type: "Case Study",
		list: {
			bg: "WK-dream-village_list-bg.jpg",
			logo: "WK-dream-village_logo-wh.png"
		},
		details: {
			goals: [
				"Logo",
				"Brand Guide",
				"Business Card Design",
				"Website Design",
				"Promotional Graphics"
			],
			summary: "Dream Village summary description goes here.",
			images: {
				header: "WK-dream-village_header.jpg",
				one: "WK-dream-village_list-bg.jpg",
				two: "WK-dream-village_list-bg.jpg",
				three: "WK-dream-village_list-bg.jpg",
				four: "WK-dream-village_list-bg.jpg",
				five: "WK-dream-village_list-bg.jpg",
				six: "WK-dream-village_list-bg.jpg",
				fonts: "WK-dream-village_list-bg.jpg"
			},
			colors: [
				"#231F20",
				"#3B90CE",
				"#73B9E6",
				"#E6E7E8"
			]
		}
	},
	{
		title: "David Nester Drums",
		slug: "david-nester",
		type: "Case Study",
		list: {
			bg: "WK-david-nester_list-bg.jpg",
			logo: "WK-david-nester_logo-wh.png"
		},
		details: {
			goals: [
				"Logo",
				"Colors",
				"Business Card Design",
				"Website Design",
				"Website Development",
				"Admin Development",
				"Social Media Graphics"
			],
			summary: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			images: {
				header: "WK-david-nester_header.jpg",
				one: "WK-david-nester_01.jpg",
				two: "WK-david-nester_02.jpg",
				three: "WK-david-nester_03.jpg",
				four: "WK-david-nester_04.jpg",
				five: "WK-david-nester_05.jpg",
				six: "WK-david-nester_06.jpg",
				fonts: "WK-david-nester_fonts.jpg"
			},
			colors: [
				"#efefef",
				"#dfdfdf",
				"#af2020",
				"#000000"
			]
		}
	}
];