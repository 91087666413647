<template>

	<div class="contact">

		<section class="header pattern">
			<div class="container">
				<h1>Contact</h1>
			</div>
		</section>

		<section class="the-form">
			<div class="container">
				<div class="success">
					<h2>Got it!</h2>
					<p>Thank you for submitting!<br>I will get back to you as soon as I can.</p>
				</div>
				<form id="contactForm" action="https://formspree.io/mnqberyo" method="POST">
					<div class="field half required">
						<label>Full Name <span>*</span></label>
						<input class="form-entry" type="text" placeholder="Full Name" required="true" name="Full Name" />
					</div>
					<div class="field half required">
						<label>Email <span>*</span></label>
						<input class="form-entry" type="email" placeholder="Email" required="true" name="Email"/>
					</div>
					<div class="field full">
						<label>Work Type Needed</label>
						<div class="select-wrap">
							<select class="form-entry" placeholder="Select One" name="Work Type Needed">
								<option value="" selected>Select One</option>
								<option value="Web Development">Web Development</option>
								<option value="Logo/Branding">Logo/Branding</option>
								<option value="Print Design">Print Design</option>
								<option value="Lettering/Calligraphy">Lettering/Calligraphy</option>
								<option value="Other">Other</option>
							</select>
						</div>
					</div>
					<div class="field full">
						<label>Message</label>
						<textarea class="form-entry" placeholder="Type your message here" name="Message"></textarea>
					</div>
					<div class="hidden-fields" style="display: none;">
						<input type="text" name="_gotcha" />
					</div>
					<div class="field full submit">
						<vue-recaptcha sitekey="6Le1l7YZAAAAABaVitd86LSwzTOkqINS5iD8RryI">
							<input type="submit" value="Submit" />
						</vue-recaptcha>
					</div>					
				</form>
			</div>
		</section>


	</div>

</template>

<script>

	import $ from "jquery";
	import VueRecaptcha from 'vue-recaptcha';

	export default {

		name: "about",
		components: { VueRecaptcha },

		// data() { return {} },

		mounted() {
			$("#contactForm").submit(function(e){
				$.ajax({
					url: "https://formspree.io/mnqberyo",
					method: "POST",
					data: $(this).serialize(),
					dataType: "json",
					success: function() { 
						$(".contact #contactForm").slideUp(300);
						$(".contact .success").slideDown(300);
					},
					error: function () {
						alert("There was an error on the form, please try again.");
					}
				});
				$(this).get(0).reset();
				e.preventDefault();
			});
		},


		// methods: {}

	};

</script>

