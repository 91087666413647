<template>

	<div class="sd-nav">
		<div class="left">
			<sd-social-media />
		</div>
		<div class="mid">
			<div class="logo" v-on:click="logoClick()">
				<router-link to="/">
					<img :src="file('logo-wh.png')" />
				</router-link>
			</div>
		</div>
		<div class="right">
			<div class="hamburger" v-on:click="navClick()">
				<span></span>
				<span></span>
				<span></span>
			</div>
			<nav v-on:click="navClick()">
				<router-link to="/about">About</router-link>
				<router-link to="/work">Work</router-link>
				<!-- <a href="#" v-scroll-to="'#contact'">Contact</a> -->
				<router-link to="/contact">Contact</router-link>
				<sd-social-media />
			</nav>
		</div>
	</div>

</template>


<script>

	import SdSocialMedia from "@/components/sd-social-media.vue";
	import $ from "jquery";

	export default {
		name: "sd-nav",
		components: { SdSocialMedia },
		// data() { return {} },
		// mounted() {},
		methods: {

			navClick(){
				$(".sd-nav nav").toggleClass("open");
				$(".sd-nav .hamburger").toggleClass("open");
				$("html, body, #app").toggleClass("open-mobile-nav");
			},

			logoClick(){
				$(".sd-nav nav").removeClass("open");
				$(".sd-nav .hamburger").removeClass("open");
				$("html, body, #app").removeClass("open-mobile-nav");
			}

		}
	};
</script>