<template>
  <div id="app" v-if="ready">
    <sd-nav />
    <div class="main-content">
      <transition name="fade">
        <router-view :key="$route.fullPath"/>
      </transition>
    </div>
    <sd-footer />
  </div>
</template>

<script>
  import SdNav from "@/components/sd-nav.vue";
  import SdFooter from "@/components/sd-footer.vue";

  export default {
    name: "app",
    components: { SdNav, SdFooter },
    data() {
      return {
        ready: false
      };
    },

    watch: {
      '$route':{
        handler: (to) => {
          document.title = to.meta.title || 'Stephens Designs'
        },
        immediate: true
      }
    },

    mounted() {
      this.init();
    },

    methods: {
      init() {
        this.ready = true;
      }
    }
  };
</script>
