<template>

	<div class="work-detail" v-if="details.exists && ready">

		<section class="header pattern" :style="{'background-image': 'url(' + details.header.src + ')'}">
			<div class="container">
				<h1>{{ details.title }}</h1>
			</div>
		</section>

		<section class="summary">
			<div class="container">
				<div class="text">
					<div class="col" v-if="details.goals.length > 0">
						<ul>
							<li><h3>Goals</h3></li>
							<li v-for="goal in details.goals" :key="goal">{{ goal }}</li>
						</ul>
					</div>
					<div class="col" v-if="details.summary.length > 0">
						<h3>Summary</h3>
						<p>{{ details.summary }}</p>
					</div>
				</div>
			</div>
		</section>

		<section class="images">
			<div class="container">
				<div class="row main">
					<div class="image">
						<img :src="details.image1.src">
					</div>
				</div>
				<div class="row supporting-2">
					<div class="col">
						<div class="image">
							<img :src="details.image2.src">
						</div>
					</div>
					<div class="col">
						<div class="image">
							<img :src="details.image3.src">
						</div>
					</div>
				</div>
				<div class="row supporting-color">
					<div class="col">
						<div class="image">
							<img :src="details.imageFonts.src">
						</div>
					</div>
					<div class="col">
						<div class="colors">
							<span v-for="color in details.colors" :key="color" :style="{ 'background-color' : color }"></span>
						</div>
					</div>
				</div>
				<div class="row supporting-3">
					<div class="col">
						<div class="image">
							<img :src="details.image4.src">
						</div>
					</div>
					<div class="col">
						<div class="image">
							<img :src="details.image5.src">
						</div>
					</div>
					<div class="col">
						<div class="image">
							<img :src="details.image6.src">
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="prev-next">
			<div class="container">
				<div class="col">
					<router-link v-if="prevWork.exists" class="blue-link" :to="`/work/${prevWork.slug}`">{{ prevWork.title }}</router-link>
				</div>
				<div class="col">
					<router-link v-if="nextWork.exists" class="blue-link" :to="`/work/${nextWork.slug}`">{{ nextWork.title }}</router-link>
				</div>
			</div>
		</section>

	</div> <!-- END TEMPLATE WRAPPER -->

</template>

<script>


	export default {

		name: "work-detail",

		data() {
			return {
				ready: false,
				details: { exists: false },
				prevWork: { exists: false },
				nextWork: { exists: false }
			};
		},


		created(){
			this.fetch();
		},

		methods: {

			fetch() {
				this.$contentful.getEntries({
					'content_type': 'work'
				})
				.then((res) => {
					if(res.items.length > 0){
						this.checkRoute(res.items);
					}
				})
				.catch(() => {
					this.ready = false;
					alert("Sorry, there was a problem fetching content.");
				})
				.finally(() => {
					this.ready = true;
				})
			},

			checkRoute($res){
				var workList = $res,
					thisSlug = this.$route.params.slug,
					thisWork = null;
				for (var i = 0; i < workList.length; i++){
					workList[i].index = i;
					if(workList[i].fields.slug == thisSlug){
						thisWork = workList[i];
					}
				};
				if(!thisWork){
					this.$router.push({ name: "FourOhFour" });
				} else {
					this.handlePrevNext(workList, thisWork.index);
					this.populatePage(thisWork);
				};
			},

			populatePage($thisWork){
				var x = { invalidFields: 0 };
				for(var prop in $thisWork.fields){
					x[prop] = this.validate($thisWork.fields[prop]);
					if(x[prop] == null){
						x.invalidFields++;
					};
				};
				if(x.invalidFields < 1){
					x.exists = true;
					this.details = x;
				};
			},

			validate($prop){
				var payload = null;
				if($prop.length > 0 && typeof $prop !== Number){
					payload = $prop;
				} else if($prop.fields !== undefined && $prop.fields.file.url.length > 0){
					payload = {
						src: "https:" + $prop.fields.file.url,
						title: $prop.fields.title
					}
				}
				return payload;
			},

			handlePrevNext($workList, $thisIndex){
				var prevNum = $thisIndex - 1;
				var nextNum = $thisIndex + 1;
				for (var i = 0; i < $workList.length; i++){
					var thisIndex = $workList[i].index;
					if(thisIndex >= 0 && thisIndex == prevNum && !this.prevWork.exists){
						this.prevWork.title = $workList[i].fields.title;
						this.prevWork.slug = $workList[i].fields.slug;
						this.prevWork.exists = true;
					}
					if(thisIndex >= 0 && thisIndex == nextNum && !this.nextWork.exists){
						this.nextWork.title = $workList[i].fields.title;
						this.nextWork.slug = $workList[i].fields.slug;
						this.nextWork.exists = true;
					}
				}
			}


		}

	};

</script>

