import Vue from "vue";
import Vuex from "vuex";

import work from "../data/work";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { work },
  mutations: {},
  actions: {}
});
