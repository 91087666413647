import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/home.vue";
import About from "../views/about.vue";
import Work from "../views/work.vue";
import Contact from "../views/contact.vue";
import WorkDetail from "../views/work-detail.vue";

import Privacy from "../views/static/privacy.vue";
import Sitemap from "../views/static/sitemap.vue";
import FourOhFour from "../views/static/FourOhFour.vue";

Vue.use(VueRouter);

export const routes = [
  { 
    path: "/", 
    name: "home", 
    component: Home,
    meta: {
      title: 'Stephens Designs | Home'
    }
  },
  { 
    path: "/about", 
    name: "about", 
    component: About,
    meta: {
      title: 'Stephens Designs | About'
    }
  },
  { 
    path: "/work", 
    name: "work", 
    component: Work,
    meta: {
      title: 'Stephens Designs | Work'
    }
  },
  { 
    path: "/contact", 
    name: "contact", 
    component: Contact,
    meta: {
      title: 'Stephens Designs | Contact'
    }
  },
  { 
    path: "/privacy-policy", 
    name: "privacy", 
    component: Privacy,
    meta: {
      title: 'Stephens Designs | Privacy Policy'
    }
  },
  { 
    path: "/sitemap", 
    name: "sitemap", 
    component: Sitemap,
    meta: {
      title: 'Stephens Designs | Sitemap'
    }
  },
  {
    path: "/work/:slug",
    name: "work-detail",
    component: WorkDetail,
    meta: {
      title: 'Stephens Designs | Work Details'
    }
  },
  { 
    path: '/404', 
    name: "FourOhFour",
    component: FourOhFour,
    meta: {
      title: 'Stephens Designs | 404'
    }
  },  
  { 
    path: '*',
    name: "FourOhFour",
    redirect: '/404' 
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {

  // this.routeHistory(window.location.href);

  window.scrollTo(0, 0);
  if (!to.matched.length) {
    next('/404');
  } else {
    next();
  }



});

export default router;