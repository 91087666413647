<template>

	<section class="sd-work-list" v-if="ready">
		<div v-if="workList.length > 0" class="wrap">
			<router-link v-for="work in workList" :key="work.title" :to="`/work/${work.slug}`">
				<div class="wk-bg"><img :src="work.listImage.src" :alt="work.listImage.title"></div>
				<div class="wk-logo"><img :src="work.logo.src" :alt="work.logo.title"></div>
				<div class="wk-text"><p>{{ work.type }}<br><span>{{ work.title }}</span></p></div>
			</router-link>
		</div>
		<div v-else class="wrap"><p class="sry">Sorry, there are no work items to show at this time.</p></div>
	</section>

</template>

<script>

	// import { mapState } from "vuex";

	export default {

		name: "sd-work-list",

		data() {
			return {
				ready: false,
				workList: []
			};
		},

		// computed: {
		// 	...mapState([
		// 		"work"
		// 	])
		// },


		created(){
			this.fetch();
		},

		methods: {
			fetch() {
				this.$contentful.getEntries({
					'content_type': 'work'
				})
				.then((res) => {
					if(res.items.length > 0){
						this.populateList(res.items);
					}
				})
				.catch((err) => {
					alert(err)
				})
				.finally(() => {
					this.ready = true;
				})
			},

			populateList($items){
				for(var i = 0; i < $items.length; i++){
					var y 		= $items[i].fields,
						x 		= { valid: true };
					x.title     = this.validate(y.title);
					x.type      = this.validate(y.type);
					x.slug      = this.validate(y.slug);
					x.listImage = this.validate(y.listImage);
					x.logo      = this.validate(y.logo);
					for (var prop in x){
						if(x[prop] == null){
							x.valid = false;
						}
					};
					if(x.valid) {
						this.workList.push(x);
					}
				}
			},

			validate($prop){
				var payload = null;
				if($prop.length > 0){
					payload = $prop;
				} else if($prop.fields !== undefined && $prop.fields.file.url.length > 0){
					payload = {
						src: "https:" + $prop.fields.file.url,
						title: $prop.fields.title
					}
				}
				return payload;
			}




		}


	};

</script>






