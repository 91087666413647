export default {
	name: "gobalmethods",
	methods: {

		file($filename){
			var fetch = require("@/assets/" + $filename);
			var payload = '';
			if(fetch.default !== undefined ){
				payload = fetch.default;
			} else {
				payload = fetch;
			}
			return payload;
		},


		backgroundImage($filename){
			return {'background-image': 'url(' + this.file($filename) + ')'};
		}

	}
};