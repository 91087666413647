import Vue from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import "./styles/main.scss";
import globalmethods from "./mixins/gobalmethods.js";
import ContentfulVue from 'contentful-vue';
import $ from "jquery";

Vue.use(ContentfulVue, {
  space: "sizj39zip42q",
  accessToken: "9UPR_ffcb0dUdk2cAiA5-IUKl91j65-TZPhrgUPvgOw"
});

Vue.mixin(globalmethods);

var VueScrollTo = require("vue-scrollto");

Vue.config.productionTip = false;
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

new Vue({
  router,
  store,
  $,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
