<template>
	<div class="home">

		<section class="hero fullpage">
			<div class="container">
				<div class="text">
					<h1><span>Graphic Design & Web Development</span>Brian Stephens</h1>
					<!-- <a href="#" v-scroll-to="'#contact'" class="button">Contact</a> -->
					<router-link class="button" to="/contact">Contact</router-link>
				</div>
			</div>
		</section>
		<section class="about-intro">
			<div class="container">
				<div class="text">
					<h2>Content & Communication First</h2>
					<p>My goal is for you to reach your goals. Your company, brand, website, and all of it's marketing materials should reach the target audience with the message and feeling needed for success. This is why I work with content and communication as the primary axiom of my process. From here we can build a successful visual language and technical system to meet your needs appropriately and without superfluous bells and whistles.</p>
					<p><router-link to="/about" class="blue-link">About Me</router-link></p>
				</div>
			</div>
		</section>

		<sd-work-list />

	</div>
</template>

<script>

	import SdWorkList from "@/components/sd-work-list.vue";
	
	export default {

		name: "home",

		components: { SdWorkList },

		// data() {
		// 	return {
				
		// 	};
		// },

		// created(){

		// },

		// methods: {

		// }


	};
</script>

