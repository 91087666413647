<template>

	<div class="sd-footer">
		<section class="upper" id="contact">
			<div class="container">
				<div class="text">
					<h2><span>Contact me today for a quote </span>Let's Build Something Awesome</h2>
					<p class="links">
						<a href="mailto:brianstephens3@gmail.com" class="blue-link">brianstephens3@gmail.com</a><a href="tel:19374225599" class="blue-link">937-422-5599</a>
					</p>
				</div>
			</div>
		</section>
		<div class="lower">
			<div>
				<p class="copy">Copyright &copy; {{ returnYear() }} Brian Stephens&nbsp;&amp;&nbsp;Clients</p>
			</div>
			<div>
				<p class="links">
					<router-link to="/privacy-policy">Privacy</router-link>
					<router-link to="/sitemap">Sitemap</router-link>
				</p>
			</div>
		</div>
	</div>

</template>

<script>

	export default {

		name: "sd-footer",
		methods: {
			returnYear() {
				return new Date().getFullYear();
			}
		}

	};

</script>
