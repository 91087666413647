<template>
	<div class="work">

		<section class="header pattern">
			<div class="container">
				<h1>Work</h1>
			</div>
		</section>

		<sd-work-list />

	</div>
</template>

<script>

	import SdWorkList from "@/components/sd-work-list.vue";
	
	export default {

		name: "work",

		components: { SdWorkList },

		// data() {
		// 	return {

		// 	};
		// },

		// created(){
		// },

		// methods: {
		// }
	};
</script>