<template>
  <div class="sitemap">
		<section class="header">
			<div class="container">
				<h1>Sitemap</h1>
			</div>
		</section>
		<section>
			<div class="container">
				<ul class="pages">
					<li><router-link class="blue-link" to="/">Home</router-link></li>
					<li><router-link class="blue-link" to="/about">About</router-link></li>
					<li><router-link class="blue-link" to="/contact">Contact</router-link></li>
					<li><router-link class="blue-link" to="/work">Work</router-link></li>
					<ul>
						<li><router-link class="blue-link" to="/work/dream-village">Dream Village</router-link></li>
						<li><router-link class="blue-link" to="/work/david-nester">David Nester Drums</router-link></li>
						<li><router-link class="blue-link" to="/work/aka-media">AKA Media Inc</router-link></li>
					</ul>
					<li><router-link class="blue-link" to="/privacy-policy">Privacy Policy</router-link></li>
					<li><router-link class="blue-link" to="/sitemap">Sitemap</router-link></li>
				</ul>
			</div>
		</section>

  </div>
</template>

<script>
export default {
  name: "sitemap"
};
</script>
