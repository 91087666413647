<template>
	<div class="fourohfour">
		<section class="fullpage">
			<div class="bg-image" :style="backgroundImage('ohwell.gif')"></div>
			<div class="container">
				<h1>404: Page Not Found</h1>
				<p>The page you're looking for doesn't exist or has moved.</p>
				<p><router-link to="/" class="blue-link arrow left">Go Home</router-link></p>
			</div>
		</section>
	</div>
</template>

<script>
	export default {

		name: "FourOhFour",

		// data() {
		// 	return {

		// 	};
		// },

		// created(){
		// },

		// methods: {
		// }

	};
</script>
