<template>
	<div class="socmed">
		<a href="https://www.linkedin.com/in/stephensdesigns/" target="_blank"><img :src="file('SD-socmed-linkedin.png')"/></a>
		<a href="https://www.instagram.com/briankilledit/" target="_blank"><img :src="file('SD-socmed-instagram.png')"/></a>
		<a href="https://dribbble.com/briankilledit" target="_blank"><img :src="file('SD-socmed-dribbble.png')"/></a>
	</div>
</template>

<script>
	export default {
		name: "sd-social-media"
		// data() {
		// 	return {};
		// },
		// computed: {
		// },
		// created(){
		// },
		// methods: {
		// }
	};
</script>






